/* eslint-disable jsx-a11y/accessible-emoji */
import React, {
    useState,
    useEffect,
    Fragment,
    useContext,
    useRef,
} from "react";
import "../assets/css/style.scss";

// Packages
import { CSSTransition } from "react-transition-group";
import {
    faBell,
    faHome,
    faMagnifyingGlass,
    faClose,
    faCaretDown,
    faAngleUp,
    faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import {
    Badge,
    Image,
    NavDropdown,
    Button,
    Nav,
    Container,
    Form,
    FormGroup,
} from "react-bootstrap";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faUser, faCircle } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

// Store
import { Context } from "./context/Store";

// Axios
import { Accounts, Elearn, Payments } from "../axiosConfig";
import {
    convertUnixTimestamp,
    getDateStr,
    getDDMMYY,
} from "./general/helpers/functions";

// Components
import PopUp from "../pages/login/pop-up";
import axios from "axios";
import { ModalContext } from "../ModalContext";
import Avatar from "react-avatar";
import SearchLoader from "./general/helpers/search-loader";
import talropLogo from "../assets/images/svg/talrop-logo.svg";

const AdminNav = () => {
    const date = new Date();
    const displayDate = getDateStr(date);
    const [selectedKey, setSelectedKey] = useState();

    const navigate = useNavigate();

    const handleSelect = (e) => {
        setSelectedKey(e);
        navigate(`/${e}`);
    };

    useEffect(() => {
        setSelectedKey(window.location.pathname.split("/")[1]);
    }, []);
    // useEffect(() => {
    //     if (selectedKey) {
    //         // console.log("inside if success");
    //         navigate(`/${selectedKey}`);
    //     }
    // }, [selectedKey]);

    useEffect(() => {
        // Load the Google Analytics script
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://www.googletagmanager.com/gtag/js?id=G-VYNC35SS62";
        document.head.appendChild(script);

        // Initialize Google Analytics
        const initScript = document.createElement("script");
        initScript.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-VYNC35SS62');
        `;
        document.head.appendChild(initScript);

        // Cleanup the script elements when the component unmounts
        return () => {
            document.head.removeChild(script);
            document.head.removeChild(initScript);
        };
    }, []);

    return (
        <nav className="admin-navigation">
            <div className="nav-container">
                <Nav
                    variant="pills"
                    activeKey={selectedKey ? selectedKey : "dashboard"}
                    onSelect={(e) => handleSelect(e)}
                >
                    <FontAwesomeIcon
                        icon={faHome}
                        onClick={() => navigate("/")}
                        className="nav-home-button"
                    />
                    <Nav.Item>
                        <Nav.Link eventKey={"dashboard"} href="">
                            <div>Dashboard</div>
                        </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item className="hide-mob">
                        <Nav.Link eventKey={"my-progress"} href="">
                            <div>Progress</div>
                        </Nav.Link>
                    </Nav.Item> */}
                    {/* <Nav.Item className="hide-mob">
                        <Nav.Link eventKey={"referral"} href="">
                            <div>Referrals</div>
                        </Nav.Link>
                    </Nav.Item> */}
                    {/* <Nav.Item className="hide-mob">
                        <Nav.Link eventKey="2">Inbox</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="3">
                            <Link to="/my-progress">Progress</Link>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="4">
                            <Link to="/referral">Referrals</Link>
                        </Nav.Link>
                    </Nav.Item> */}
                    {/* <NavDropdown
                        title="•••"
                        id="nav-dropdown"
                        className="remove-caret"
                    >
                        <NavDropdown.Item eventKey="4.1" className="hide-des">
                            Inbox
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="4.2" className="hide-des">
                            <Link to="/my-progress">Progress</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="4.3" className="hide-des">
                            <Link to="/referral">Referrals</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="4.4">
                            Action
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="4.5">
                            Another action
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="4.6">
                            Something else here
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item eventKey="4.7">
                            Separated link
                        </NavDropdown.Item>
                    </NavDropdown> */}
                </Nav>
                <span>{displayDate}</span>
            </div>
        </nav>
    );
};

const dashboardPaths = [
    "/dashboard",
    // "/referral",
    // "/my-progress",
    // "/bookmarks",
    // "/play-list",
    // "/browse-trailers",
    // "/gift-card",
    // "/playlist",
    // "/articles-details",
    // "/articles",
    // "/discussion",
    // "/discussion-details",
    // "/communities",
    // "/settings",
    // "/course-completion",
    // "/case-study",
    // "/instructor",
    // "/topic-quiz",
    // "/topic-quiz-completion",
    // "/course-details",
    // "/nonmember",
    // "/member",
    // "/course-detail",
];

export default function Header(props) {
    const navigate = useNavigate();
    const [isNavVisible, setNavVisibility] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [languageData, setLanguageData] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState("All");
    const [courseCategoryData, setCourseCategoryData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("All Categories");
    const [profileExpanded, setProfileExpanded] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [profile, setProfile] = useState(false);
    const [isNotification, setIsNotification] = useState(false);
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const { pathname } = useLocation();
    const [searchContainer, setSearchContainer] = useState(false);
    const [query, setQuery] = useState("");
    const [searchData, setSearchData] = useState([]);
    const [isSearchData, setIsSearchData] = useState(false);
    const [debounceTimer, setDebounceTimer] = useState(0);
    // const [isCollapsed, setIsCollapsed] = useState(false);
    const [searchBar, setSearchBar] = useState(false);

    // const handleDropdownSelect = (eventKey) => {
    //     // Toggle the collapse state when a selection is made
    //     setIsCollapsed(!isCollapsed);
    // };

    const { isError, setIsError } = useContext(ModalContext);

    const { state, dispatch } = useContext(Context);
    const accessToken = state.user_data.accessToken;
    const userId = state.user_data.userId;
    const elearn_data = state.elearn_data;
    const profilePicture = state.user_data.profilePicture;
    const userSubscriptionData = state?.user_subscription;
    const signup = state?.user_data.isSignup;
    const fname = state?.user_data.firstName;
    const lname = state?.user_data.lastName;
    // const subscription = state?.user_data?.isSubscribed;
    // const subscriptionExpiry = state?.user_data?.subscriptionExpiry;

    // const [showModal, setShowModal] = useState(false);

    const notificationData = [
        {
            id: 1,
            description:
                "Hey Laura, your Monthly Report is ready for you Review",
            date: "19-04-22",
            time: "5:30 pm",
        },
        {
            id: 2,
            description:
                "Hey Laura, your Monthly Report is ready for you Review",
            date: "19-04-22",
            time: "5:30 pm",
        },
        {
            id: 3,
            description:
                "Hey Laura, your Monthly Report is ready for you Review",
            date: "19-04-22",
            time: "5:30 pm",
        },
        {
            id: 4,
            description:
                "Hey Laura, your Monthly Report is ready for you Review",
            date: "19-04-22",
            time: "5:30 pm",
        },
        {
            id: 5,
            description:
                "Hey Laura, your Monthly Report is ready for you Review",
            date: "19-04-22",
            time: "5:30 pm",
        },
    ];

    // let login = localStorage.getItem("login");
    // login = JSON.parse(login);
    // dispatch({ type: "UPDATE_SESSION_DATA", login: login });

    // // console.log(sessionBool,"============ header ================");

    const notifications = notificationData.map((n, index) => {
        return (
            <div key={n.id + index} className="notification">
                <p>{n.description}</p>
                <div className="notification-date">
                    <span>{n.date}</span>
                    <span>{n.time}</span>
                </div>
            </div>
        );
    });

    const handleProfileDataFetch = () => {
        if (accessToken.length > 0) {
            Accounts.get(`api/v2/users/${userId}/profile`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then((response) => {
                    setFirstName(response?.data.first_name);
                    setLastName(response?.data.last_name);
                    // setPhoto(response.data.image);
                    dispatch({
                        type: "UPDATE_USER_DATA",
                        user_data: {
                            profilePicture: response?.data.image,
                            firstName: response?.data.first_name,
                            lastName: response?.data.last_name,
                        },
                    });
                })
                .catch((error) => {
                    // console.log(error, "REsponse of Header Profile Data in Header");
                });
        }
    };

    const location = useLocation();
    let urlSlug = location.pathname.replace("/category/", "");

    useEffect(() => {
        let user_data = localStorage.getItem("user_data");
        user_data = JSON.parse(user_data);
        dispatch({ type: "UPDATE_USER_DATA", user_data: user_data });

        const mediaQuery = window.matchMedia("(max-width: 992px)");
        mediaQuery.addListener(handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery);
        // handleSubscriptionData();

        if (accessToken) {
            handleProfileDataFetch();
        }

        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, [profilePicture, accessToken, signup, fname, lname]);

    const handleLanguage = () => {
        Elearn.get("api/v1/learn/languages/")
            .then((response) => {
                setLanguageData(response.data);
            })
            .catch((error) => {
                // // console.log(error,"error");
            });
    };

    const handleCategories = () => {
        Elearn.get("api/v1/learn/categories/")
            .then((response) => {
                setCourseCategoryData(response.data);
                if (
                    urlSlug.includes("all") ||
                    elearn_data.activeCategory == "all"
                ) {
                    setSelectedCategory("All Categories");
                }
                response.data.map((category) => {
                    if (urlSlug === category.slug) {
                        setSelectedCategory(category.name);
                    }
                    // else if(elearn_data.activeCategory == category.slug){
                    //     setSelectedCategory(category.name);
                    // };
                });
            })
            .catch((error) => {
                // // console.log(error,"error");
            });
    };

    const handleUserSubscriptionData = () => {
        if (accessToken) {
            Payments.get("api/v1/subscriptions/get-subscription-status/", {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then((response) => {
                    dispatch({
                        type: "UPDATE_SUBSCRIPTION_DATA",
                        user_subscription: {
                            isSubscribed: response.data.is_subscribed,
                            plan: response.data.plan,
                            price: response.data.price,
                            expiringOn: response.data.expiring_on,
                        },
                    });
                    // setUserSubscriptionData(response.data);
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
    };

    useEffect(() => {
        // handleLanguage();
        handleCategories();
        // handleUserSubscriptionData();

        handleCategorySelection();
    }, [urlSlug]);

    useEffect(() => {
        let user_subscription = localStorage.getItem("user_subscription");
        user_subscription = JSON.parse(user_subscription);
        dispatch({
            type: "UPDATE_SUBSCRIPTION_DATA",
            user_subscription: user_subscription,
        });

        handleUserSubscriptionData();
    }, [window?.location?.pathname]);

    // useEffect(() => {
    //     if(isNotification || expanded){
    //         document.body.style.overflow = "hidden";
    //     }else{
    //         document.body.style.overflow = "scroll";
    //     }
    // })

    const toggleNav = () => {
        setNavVisibility(!isNavVisible);
        if (!isNavVisible) {
            setExpanded(false);
        }
    };

    const handleCategorySelection = () => {
        if (urlSlug == "all") {
            setSelectedCategory("All Categories");
        } else {
            courseCategoryData.map((item) => {
                urlSlug == item.slug && setSelectedCategory(item.name);
            });
        }
    };

    const handleMediaQueryChange = (mediaQuery) => {
        if (mediaQuery.matches) {
            setIsSmallScreen(true);
        } else {
            setIsSmallScreen(false);
        }
    };

    const handleCSRF = () => {
        axios
            .get("http://localhost:8009/api/v2/users/csrf", {
                headers: { Authorization: null },
                credentials: "same-origin",
            })
            .then((response, request) => {
                // console.log("CSRF CALL ON 403", response);
                handleTokenRefresh(response.headers["x-csrftoken"]);
            });
    };

    const handleTokenRefresh = async (token) => {
        // console.log("error from header handleTokenRefresh in");

        try {
            const response = await fetch(
                "http://localhost:8009/api/v2/users/token-refresh",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": token,
                    },
                    credentials: "include",
                }
            );
            const data = await response.json();

            handleLogoutRequest(data?.access_token);
            dispatch({
                type: "UPDATE_USER_DATA",
                user_data: {
                    accessToken: data?.access_token,
                    tokenExpiry: data?.expire_at,
                },
            });
        } catch (error) {
            console.error(error);
            // setLogOutBool(true);
        }
    };

    const handleLogoutRequest = async (accessToken) => {
        Accounts.post(
            "api/v2/users/logout-everywhere",
            {},
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                // withCredentials: true
            }
        )
            .then((response) => {
                if (response.status === 200) {
                    window.localStorage.clear();
                    setTimeout(() => {
                        window.location.reload();
                        window.location.href = "/join";
                    }, 200);
                } else if (response.status === 401) {
                    // console.log( "error from header 401");
                    handleCSRF();
                }
            })
            .catch((error) => {
                // console.log(error, "error from header error");
                if (error.response.status === 401) {
                    // console.log(error, "error from header 401 in error");
                    handleCSRF();
                }
            });
    };

    //----------OutsideClick---------------------------------------------------
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (ref === wrapperRef) {
                        setProfile(false);
                    } else if (ref === notificationWrapperRef) {
                        setIsNotification(false);
                    }
                    //   setIsShow(false);
                    // setIsProfile(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    const notificationWrapperRef = useRef(null);
    useOutsideClick(wrapperRef);
    useOutsideClick(notificationWrapperRef);
    //------------------------------------------------------------------------------------

    const DropDownNav = Array.isArray(courseCategoryData)
        ? courseCategoryData?.map((item, index) => {
              return (
                  <NavDropdown.Item
                      onClick={() => {
                          toggleNav();
                          setSelectedCategory(item.name);
                          dispatch({
                              type: "UPDATE_ELEARN_DATA",
                              elearn_data: {
                                  activeCategory: item?.course_category?.slug,
                              },
                          });
                      }}
                      key={index}
                  >
                      <Link to={`/category/${item.slug}`}>{item.name}</Link>
                  </NavDropdown.Item>
              );
          })
        : null;

    // const LanguageDropDownNav = Array.isArray(languageData)
    //     ? languageData.map((item, index) => {
    //           return (
    //               <NavDropdown.Item
    //                   className="text-center"
    //                   onClick={() => setSelectedLanguage(item.name)}
    //                   key={index}
    //               >
    //                   <Link to="#" onClick={toggleNav}>
    //                       {item.name}
    //                   </Link>
    //               </NavDropdown.Item>
    //           );
    //       })
    //     : null;

    const handlePlanString = (value) => {
        const str = value?.charAt(0) + value?.slice(1).toLowerCase();
        return str;
    };

    const handleSearch = (value) => {
        setIsSearchData(true);
        if (value.length > 0) {
            setSearchContainer(true);
            Elearn.get(`api/v1/learn/search/?q=${value}`)
                .then((response) => {
                    setSearchData(response.data);
                    setIsSearchData(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsSearchData(false);
                });
        } else {
            setSearchContainer(false);
            setSearchData([]);
        }
    };

    useEffect(() => {
        if (debounceTimer !== 0) {
            clearTimeout(debounceTimer);
        }
        const timer = setTimeout(() => {
            handleSearch(query);
        }, 500);
        setDebounceTimer(timer);
    }, [query]);

    return (
        <Fragment>
            <header className="Header container-fluid sticky-top">
                {/* {(showModal === true
                    // props.logOutPopUp === true 
                    // && logOutPopUpBinary === 1
                    ) && 
                    <PopUp setLogOutPopUp={props.setLogOutPopUp} setLogOutPopUpBinary={setLogOutPopUpBinary}/>
                } */}
                {isError === true && <PopUp />}
                <div className="header-right-container">
                    <Link to="/" className="header-logo-container">
                        <img
                            src={require("../assets/images/Wlogo.png")}
                            className="Logo"
                            alt="logo"
                        />
                    </Link>
                    <div className="flag-container">
                        <span className="border-line"></span>
                        <span>An initiative from</span>
                        <a
                            href="https://talrop.com/"
                            target="_blank"
                            className="flag-logo"
                        >
                            <img src={talropLogo} className="flag" alt="flag" />
                        </a>
                    </div>
                </div>
                <CSSTransition
                    in={!isSmallScreen || isNavVisible}
                    timeout={350}
                    classNames="NavAnimation"
                    unmountOnExit
                >
                    <nav className="Nav">
                        <div
                            onClick={() => {
                                toggleNav();
                                setExpanded(false);
                            }}
                            className="Burger-close"
                        >
                            <FontAwesomeIcon icon={faClose} />
                        </div>
                        {/* {isSmallScreen && (
                            <Form className="search-form">
                                <FormGroup className="search-form-icon">
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    <Input
                                        type="text"
                                        name="search"
                                        placeholder="Search"
                                    />
                                </FormGroup>
                            </Form>
                        )} */}
                        {isSmallScreen && (
                            <Form className="search-form">
                                <FormGroup
                                    className="search-form-icon"
                                    onClick={() => {
                                        setSearchContainer(!searchContainer);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    <Input
                                        type="text"
                                        name="search"
                                        placeholder="Search"
                                        value={query}
                                        onChange={(e) => {
                                            setQuery(e.target.value);
                                        }}
                                        autoComplete="off"
                                    />
                                </FormGroup>

                                <div
                                    className={
                                        !searchContainer
                                            ? "drop-height"
                                            : "search-data"
                                    }
                                >
                                    <div className="search-container-scroll-box">
                                        {isSearchData ? (
                                            <SearchLoader />
                                        ) : searchData.length > 0 ? (
                                            searchData?.map((data) => (
                                                <div
                                                    className="search-data-main-container"
                                                    onClick={() => {
                                                        setSearchContainer(
                                                            false
                                                        );
                                                        setSearchData([]);
                                                        toggleNav();
                                                        if (data?.course_slug) {
                                                            navigate(
                                                                `/courses/${data?.course_slug}`
                                                            );
                                                        } else {
                                                            navigate(
                                                                `/instructor/${data?.instructor_slug}`
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {data.images ? (
                                                        <div className="search-box-image-container">
                                                            {/* <img src={data.image} alt="Search Image" /> */}
                                                            <img
                                                                src={
                                                                    data?.images.find(
                                                                        (a) =>
                                                                            a.image_type.toLowerCase() ==
                                                                            "profile"
                                                                    )?.image
                                                                }
                                                                alt="Instructor"
                                                            />
                                                        </div>
                                                    ) : null}
                                                    <div className="search-box-content">
                                                        <h3>{data?.name}</h3>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="search-data-main-container">
                                                No data found
                                            </div>
                                        )}
                                        {/* <div className="load-more">
                                            Load more
                                        </div> */}
                                    </div>
                                </div>
                            </Form>
                        )}
                        {/* <NavDropdown title={selectedLanguage}>
                            {LanguageDropDownNav}
                            <NavDropdown.Item className="text-center"><Link to="#">ES</Link></NavDropdown.Item>
                                <NavDropdown.Item className="text-center"><Link to="#">DE</Link></NavDropdown.Item>
                                <NavDropdown.Item className="text-center"><Link to="#">FR</Link></NavDropdown.Item>
                        </NavDropdown> */}
                        {/* ------------ START : Web responsive view category selection ------------  */}
                        <div className="category-container">
                            <div className="wise-craft-container">
                                {" "}
                                <Link to="/wise-craft">Wise Craft</Link>
                            </div>
                            <NavDropdown
                                title="All Categories"
                                id="basic-nav-dropdown"
                                className="navdrop1"
                                collapseonselect="true"
                                // onSelect={(e) => handleDropdownSelect(e)}
                                // show={isCollapsed}
                            >
                                {/* <NavDropdown.Toggle aria-controls="basic-nav-dropdown" /> */}
                                {/* <NavDropdown.Item
                                onClick={() => {
                                    toggleNav();
                                    setSelectedCategory("All Categories");
                                    dispatch({
                                        type: "UPDATE_ELEARN_DATA",
                                        elearn_data: {
                                            activeCategory: "all",
                                        },
                                    });
                                }}
                            >
                                <Link to="/category/all">
                                    All Categories{" "}
                                </Link>
                            </NavDropdown.Item> */}
                                {DropDownNav}
                                {/* <NavDropdown.Item ><Link to='/category/'>Arts & Entertainment</Link></NavDropdown.Item>
                            <NavDropdown.Item ><Link to='/category/'>Home & Lifestyle</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link to='/category/'>Food</Link></NavDropdown.Item>
                            <NavDropdown.Item ><Link to='/category/'>Business</Link></NavDropdown.Item>
                            <NavDropdown.Item ><Link to='/category/'>Music</Link></NavDropdown.Item>
                            <NavDropdown.Item ><Link to='/category/'>Writing</Link></NavDropdown.Item>
                            <NavDropdown.Item ><Link to='/category/'>Design & Style</Link></NavDropdown.Item>
                            <NavDropdown.Item ><Link to='/category/'>Sports & Gaming</Link></NavDropdown.Item>
                            <NavDropdown.Item ><Link to='/category/'>Community & Government</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link to='/category/'>Wellness</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link to='/category/'>Science & Tech</Link></NavDropdown.Item>
                            <NavDropdown.Item ><Link to='/category/'>30-Day Sessions</Link></NavDropdown.Item> */}
                                <NavDropdown.Item className="last-item">
                                    <Button
                                        href="#"
                                        className="dropdown-btn"
                                        onClick={(event) =>
                                            navigate("/category/all")
                                        }
                                    >
                                        Discover
                                    </Button>
                                </NavDropdown.Item>
                            </NavDropdown>
                        </div>
                        {/* ------------ END : Web responsive view category selection ------------  */}
                        {/* {!isSmallScreen && (
                            <Form className="search-form">
                                <FormGroup
                                    className="search-form-icon" */}
                        {/* // onClick={() => setSearchContainer(!searchContainer)} */}
                        {/* // > */}
                        {/* <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    <Input
                                        type="text"
                                        name="search"
                                        placeholder="Search"
                                        value={query}
                                        onChange={(e) => {
                                            setQuery(e.target.value);
                                        }}
                                        autoComplete="off"
                                    />
                                </FormGroup>

                                <div
                                    className={
                                        !searchContainer
                                            ? "drop-height"
                                            : "search-data"
                                    }
                                >
                                    <div className="search-container-scroll-box">
                                        {isSearchData ? (
                                            <SearchLoader />
                                        ) : searchData.length > 0 ? (
                                            searchData?.map((data) => (
                                                <div
                                                    className="search-data-main-container"
                                                    onClick={() => {
                                                        setSearchContainer(
                                                            false
                                                        );
                                                        setSearchData([]);
                                                        if (data?.course_slug) {
                                                            navigate(
                                                                `/courses/${data?.course_slug}`
                                                            );
                                                        } else {
                                                            navigate(
                                                                `/instructor/${data?.instructor_slug}`
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {data.images ? (
                                                        <div className="search-box-image-container"> */}
                        {/* <img src={data.image} alt="Search Image" /> */}
                        {/* <img
                                                                src={
                                                                    data?.images.find(
                                                                        (a) =>
                                                                            a.image_type.toLowerCase() ==
                                                                            "profile"
                                                                    )?.image
                                                                }
                                                                alt="Instructor"
                                                            />
                                                        </div>
                                                    ) : null}
                                                    <div className="search-box-content">
                                                        <h3>{data?.name}</h3>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="search-data-main-container">
                                                No data found
                                            </div>
                                        )} */}
                        {/* <div className="load-more">
                                            Load more
                                        </div> */}
                        {/* </div>
                                </div>
                            </Form>
                        )} */}
                        {/* ------------ START : Mobile responsive view category selection ------------  */}
                        <div className="fullcontainer">
                            {searchBar ? (
                                !isSmallScreen && (
                                    <Form className="search-form">
                                        <FormGroup
                                            className="search-form-icon"
                                            // onClick={() => setSearchContainer(!searchContainer)}
                                        >
                                            <FontAwesomeIcon
                                                icon={faMagnifyingGlass}
                                            />
                                            <Input
                                                type="text"
                                                name="search"
                                                placeholder="Search"
                                                value={query}
                                                onChange={(e) => {
                                                    setQuery(e.target.value);
                                                }}
                                                autoComplete="off"
                                            />
                                            <div className="close-icon">
                                                <FontAwesomeIcon
                                                    icon={faClose}
                                                    onClick={() => {
                                                        setSearchBar(false);
                                                        setQuery("");
                                                    }}
                                                />
                                            </div>
                                        </FormGroup>

                                        <div
                                            className={
                                                !searchContainer
                                                    ? "drop-height"
                                                    : "search-data"
                                            }
                                        >
                                            <div className="search-container-scroll-box">
                                                {isSearchData ? (
                                                    <SearchLoader />
                                                ) : searchData.length > 0 ? (
                                                    searchData?.map((data) => (
                                                        <div
                                                            className="search-data-main-container"
                                                            onClick={() => {
                                                                setSearchContainer(
                                                                    false
                                                                );
                                                                setSearchData(
                                                                    []
                                                                );
                                                                if (
                                                                    data?.course_slug
                                                                ) {
                                                                    navigate(
                                                                        `/courses/${data?.course_slug}`
                                                                    );
                                                                } else {
                                                                    navigate(
                                                                        `/instructor/${data?.instructor_slug}`
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            {data.images ? (
                                                                <div className="search-box-image-container">
                                                                    {/* <img src={data.image} alt="Search Image" /> */}
                                                                    <img
                                                                        src={
                                                                            data?.images.find(
                                                                                (
                                                                                    a
                                                                                ) =>
                                                                                    a.image_type.toLowerCase() ==
                                                                                    "profile"
                                                                            )
                                                                                ?.image
                                                                        }
                                                                        alt="Instructor"
                                                                    />
                                                                </div>
                                                            ) : null}
                                                            <div className="search-box-content">
                                                                <h3>
                                                                    {data?.name}
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="search-data-main-container">
                                                        No data found
                                                    </div>
                                                )}
                                                {/* <div className="load-more">
                                                Load more
                                            </div> */}
                                            </div>
                                        </div>
                                        <span className="border-line"></span>
                                    </Form>
                                )
                            ) : (
                                <div className="search-icon-container">
                                    <div
                                        onClick={() => setSearchBar(true)}
                                        className="search-icon"
                                    >
                                        <FontAwesomeIcon
                                            icon={faMagnifyingGlass}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="wise-craft">
                                <span className="border-line"></span>
                            </div>
                            <div className="wise-craft-mobile">
                                <Link
                                    to="/wise-craft"
                                    onClick={() => {
                                        toggleNav();
                                        setExpanded(false);
                                    }}
                                >
                                    Wise Craft
                                </Link>
                            </div>

                            <div className="contain-head">
                                <Link
                                    to="/category/all"
                                    onClick={() => toggleNav()}
                                >
                                    Categories{" "}
                                </Link>
                                <button
                                    className="faq-btn"
                                    onClick={() => setExpanded(!expanded)}
                                >
                                    {expanded ? (
                                        <FontAwesomeIcon icon={faAngleUp} />
                                    ) : (
                                        <FontAwesomeIcon icon={faAngleDown} />
                                    )}
                                </button>
                            </div>

                            {expanded && (
                                <div className="contains">
                                    <NavDropdown.Item
                                        onClick={() => {
                                            setSelectedCategory(
                                                "All Categories"
                                            );
                                            dispatch({
                                                type: "UPDATE_ELEARN_DATA",
                                                elearn_data: {
                                                    activeCategory: "all",
                                                },
                                            });
                                        }}
                                    >
                                        <Link
                                            to="/category/all"
                                            onClick={toggleNav}
                                        >
                                            All{" "}
                                        </Link>
                                    </NavDropdown.Item>
                                    {DropDownNav}
                                    {/* <a href="">Dance</a>
                                <a href="">Singing</a>
                                <a href="">Writing</a>
                                <a href="">Business</a> */}
                                    {/* <Button href="#" className="dropdown-btn">
                                    Discover
                                </Button> */}
                                </div>
                            )}
                            {/* ------------ END : Mobile responsive view category selection ------------  */}

                            {!accessToken && (
                                <Fragment>
                                    <div className="join-membership">
                                        <div
                                            className="nav-items"
                                            onClick={toggleNav}
                                        >
                                            <Link
                                                to="/membership"
                                                state={{ from: pathname }}
                                            >
                                                Membership
                                            </Link>
                                        </div>
                                        <span className="border-line"></span>
                                        <div
                                            className="nav-items"
                                            onClick={toggleNav}
                                        >
                                            <Link to="/join">Join</Link>
                                        </div>
                                    </div>
                                </Fragment>
                            )}
                            {accessToken && (
                                <Fragment>
                                    <div className="notificationbox">
                                        {/* <div className="contain-head">
                                        <Link to="/category/all">
                                            {" "}
                                            All Categories{" "}
                                        </Link>

                                  <div className="nav-item">
                                    <div
                                        className="profileImg"
                                        onClick={() => setProfile(!profile)}
                                    >
                                        {/* <Image
                                            src={require(`../assets/images/profile-picture.png`)}
                                        /> */}
                                        {/* <div className="profile-image-container">
                                        {profilePicture ? (
                                            <Image
                                                src={`${profilePicture}`}
                                                alt="Photo"
                                            />
                                        ) : (
                                            <Image
                                                src={require(`../assets/images/profile-picture.png`)}
                                                alt="Photo Default"
                                            />
                                        )}
                                    </div>
                                    <button
                                        className="faq-btn"
                                        onClick={() => setExpanded(!expanded)}
                                    >
                                        {expanded ? (
                                            <FontAwesomeIcon icon={faAngleUp} />
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={faAngleDown}
                                            />
                                        )}
                                    </button> */}

                                        {/* {expanded && (
                                        <div className="contains">
                                            <a href="">Dance</a>
                                            <a href="">Singing</a>
                                            <a href="">Writing</a>
                                            <a href="">Business</a>
                                            <Button
                                                href="#"
                                                className="dropdown-btn"
                                            >
                                                Discover
                                            </Button>
                                        </div>
                                    )} */}

                                        {/* <div className="notificationsicon" >
                                            <div
                                                onClick={() =>
                                                    setIsNotification(
                                                        !isNotification
                                                    )
                                                }
                                                className="nav-item icon has-badge"
                                            >
                                                <div ref={notificationWrapperRef}>
                                                    <FontAwesomeIcon
                                                        icon={faBell}
                                                    />
                                                    <h6>Notifications</h6>
                                                    <Badge bg="branded">19</Badge>
                                                    {isNotification && (
                                                        <section className="device-notifications">
                                                            <div className="device-notifications-title">
                                                                <h3>
                                                                    Notifications
                                                                </h3>
                                                                <div className="notifications-head">
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faBell
                                                                        }
                                                                    />
                                                                </div>
                                                                <hr></hr>
                                                            </div>
                                                            <div className="notifications-info">
                                                                {notifications}
                                                            </div>
                                                        </section>
                                                    )}
                                                </div>

                                            </div>
                                        </div> */}

                                        <Link
                                            to="/dashboard"
                                            className="nav-item icon"
                                            onClick={() => {
                                                toggleNav();
                                                setExpanded(false);
                                            }}
                                        >
                                            <h6>My Progress</h6>
                                        </Link>
                                        <span className="border-line"></span>
                                        {/* <Link
                                            to="/settings"
                                            className="nav-item icon"
                                            onClick={() => {
                                                toggleNav();
                                                setExpanded(false);
                                            }} 
                                        >
                                            <h5>Settings</h5>
                                            <FontAwesomeIcon icon={faGear} />
                                        </Link> */}

                                        <div className="nav-item-box">
                                            <div className="nav-item">
                                                <h5
                                                    onClick={() => {
                                                        toggleNav();
                                                        navigate("/settings");
                                                    }}
                                                >
                                                    Profile
                                                </h5>

                                                <div
                                                    className="profileImg"
                                                    // onClick={() =>
                                                    //     setProfile(!profile)
                                                    // }
                                                    // onMouseDown={() =>
                                                    //     setProfile(true)
                                                    // }
                                                    onMouseEnter={() =>
                                                        setProfile(true)
                                                    }
                                                    onMouseLeave={() =>
                                                        setProfile(false)
                                                    }
                                                >
                                                    {/* <h6
                                                        ref={wrapperRef}
                                                        onClick={() => {
                                                            setProfile(
                                                                !profile
                                                            );
                                                            setProfileExpanded(
                                                                !profileExpanded
                                                            );
                                                        }}
                                                    >
                                                        {firstName} {lastName}
                                                    </h6> */}
                                                    <div
                                                        className="profile-image-container"
                                                        ref={wrapperRef}
                                                        onClick={() => {
                                                            setProfile(
                                                                !profile
                                                            );
                                                            setProfileExpanded(
                                                                !profileExpanded
                                                            );
                                                        }}
                                                    >
                                                        {profilePicture ? (
                                                            <Image
                                                                src={`${profilePicture}`}
                                                                alt="Photo"
                                                            />
                                                        ) : // <Image
                                                        //     src={require(`../assets/images/profile-picture.png`)}
                                                        //     alt="Photo Default"
                                                        // />
                                                        firstName ||
                                                          lastName ? (
                                                            <Avatar
                                                                name={`${firstName} ${lastName}`}
                                                                round={true}
                                                                size="40"
                                                            />
                                                        ) : (
                                                            // <Avatar name="WiseTalkies" round={true} size="40"/>
                                                            <div className="default-image">
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faUser
                                                                    }
                                                                />
                                                            </div>
                                                        )}

                                                        {profile == true ? (
                                                            <div
                                                                className="overlay-header"
                                                                onMouseEnter={() =>
                                                                    setProfile(
                                                                        true
                                                                    )
                                                                }
                                                                onMouseLeave={() =>
                                                                    setProfile(
                                                                        false
                                                                    )
                                                                }
                                                            >
                                                                <div className="menu-popup-links">
                                                                    <div className="header-subscription">
                                                                        <h6 className="profile-name">
                                                                            {
                                                                                firstName
                                                                            }{" "}
                                                                            {
                                                                                lastName
                                                                            }
                                                                        </h6>
                                                                        <div className="header-subscription-state">
                                                                            <span>
                                                                                Plan
                                                                                Status
                                                                            </span>
                                                                            <span className="header-subscription-span-state">
                                                                                <FontAwesomeIcon
                                                                                    icon={
                                                                                        faCircle
                                                                                    }
                                                                                />{" "}
                                                                                {userSubscriptionData?.isSubscribed ? (
                                                                                    <small>
                                                                                        Active
                                                                                    </small>
                                                                                ) : (
                                                                                    <small>
                                                                                        Inactive
                                                                                    </small>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        <div className="header-subscription-hr-line"></div>
                                                                        <div className="header-subscription-content">
                                                                            {userSubscriptionData?.isSubscribed ? (
                                                                                <>
                                                                                    <h6>
                                                                                        active
                                                                                        plan:{" "}
                                                                                        <span>
                                                                                            {handlePlanString(
                                                                                                userSubscriptionData?.plan
                                                                                            )}
                                                                                        </span>
                                                                                    </h6>
                                                                                    <h5 className="expiry">
                                                                                        Expires
                                                                                        on{" "}
                                                                                        <small>
                                                                                            {getDateStr(
                                                                                                userSubscriptionData?.expiringOn
                                                                                            )}
                                                                                        </small>
                                                                                    </h5>
                                                                                </>
                                                                            ) : (
                                                                                <div className="header-subscription-no-subscription">
                                                                                    <h6>
                                                                                        No
                                                                                        Active
                                                                                        Subscription
                                                                                    </h6>
                                                                                    <Button
                                                                                        onClick={() => {
                                                                                            dispatch(
                                                                                                {
                                                                                                    type: "UPDATE_SESSION_DATA",
                                                                                                    general:
                                                                                                        {
                                                                                                            prevPath:
                                                                                                                "/",
                                                                                                        },
                                                                                                }
                                                                                            );
                                                                                            navigate(
                                                                                                "/membership",
                                                                                                {
                                                                                                    state: {
                                                                                                        from: "header",
                                                                                                    },
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                        className="button-effect-primary"
                                                                                    >
                                                                                        Subscribe
                                                                                    </Button>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="header-profile-content">
                                                                        <Link
                                                                            to="/settings"
                                                                            onClick={() =>
                                                                                toggleNav()
                                                                            }
                                                                        >
                                                                            Settings{" "}
                                                                        </Link>
                                                                        <span
                                                                            onClick={() => {
                                                                                handleLogoutRequest(
                                                                                    accessToken
                                                                                );
                                                                            }}
                                                                        >
                                                                            Logout
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <button
                                                        className="faq-btn"
                                                        onClick={() => {
                                                            setProfile(
                                                                !profile
                                                            );
                                                            setProfileExpanded(
                                                                !profileExpanded
                                                            );
                                                        }}
                                                    >
                                                        {profileExpanded ? (
                                                            <FontAwesomeIcon
                                                                icon={faAngleUp}
                                                            />
                                                        ) : (
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faAngleDown
                                                                }
                                                            />
                                                        )}
                                                    </button>
                                                </div>
                                            </div>

                                            {profileExpanded && (
                                                <div className="contains">
                                                    <div
                                                        className="contains-overlay-header"
                                                        onMouseEnter={() =>
                                                            setProfile(true)
                                                        }
                                                        onMouseLeave={() =>
                                                            setProfile(false)
                                                        }
                                                    >
                                                        <div className="menu-popup-links">
                                                            <div className="header-subscription">
                                                                <h6 className="profile-name">
                                                                    {firstName}{" "}
                                                                    {lastName}
                                                                </h6>
                                                                <div className="header-subscription-state">
                                                                    <span>
                                                                        Plan
                                                                        Status
                                                                    </span>
                                                                    <span className="header-subscription-span-state">
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faCircle
                                                                            }
                                                                        />{" "}
                                                                        {userSubscriptionData?.isSubscribed ? (
                                                                            <small>
                                                                                Active
                                                                            </small>
                                                                        ) : (
                                                                            <small>
                                                                                Inactive
                                                                            </small>
                                                                        )}
                                                                    </span>
                                                                </div>
                                                                <div className="header-subscription-hr-line"></div>
                                                                <div className="header-subscription-content">
                                                                    {userSubscriptionData?.isSubscribed ? (
                                                                        <>
                                                                            <h6>
                                                                                active
                                                                                plan:{" "}
                                                                                <span>
                                                                                    {handlePlanString(
                                                                                        userSubscriptionData?.plan
                                                                                    )}
                                                                                </span>
                                                                            </h6>
                                                                            <h5 className="expiry">
                                                                                Expires
                                                                                on{" "}
                                                                                <small>
                                                                                    {getDateStr(
                                                                                        userSubscriptionData?.expiringOn
                                                                                    )}
                                                                                </small>
                                                                            </h5>
                                                                        </>
                                                                    ) : (
                                                                        <div className="header-subscription-no-subscription">
                                                                            <h6>
                                                                                No
                                                                                Active
                                                                                Subscription
                                                                            </h6>
                                                                            <Button
                                                                                onClick={() => {
                                                                                    dispatch(
                                                                                        {
                                                                                            type: "UPDATE_SESSION_DATA",
                                                                                            general:
                                                                                                {
                                                                                                    prevPath:
                                                                                                        "/",
                                                                                                },
                                                                                        }
                                                                                    );
                                                                                    navigate(
                                                                                        "/membership",
                                                                                        {
                                                                                            state: {
                                                                                                from: "header",
                                                                                            },
                                                                                        }
                                                                                    );
                                                                                    toggleNav();
                                                                                }}
                                                                                className="button-effect-primary"
                                                                            >
                                                                                Subscribe
                                                                            </Button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="header-profile-content">
                                                                <Link
                                                                    to="/settings"
                                                                    onClick={() =>
                                                                        toggleNav()
                                                                    }
                                                                >
                                                                    Settings{" "}
                                                                </Link>
                                                                <span
                                                                    onClick={() => {
                                                                        handleLogoutRequest(
                                                                            accessToken
                                                                        );
                                                                    }}
                                                                >
                                                                    Logout
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    </nav>
                </CSSTransition>

                {/* Mobile responsive */}
                <div className="mobile-box">
                    <div className="mobile-serach">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </div>

                    <div className="notifications">
                        {/* {accessToken && 
                                <div
                                    onClick={() => setIsNotification(!isNotification)}
                                    className="nav-item icon has-badge"
                                    ref={notificationWrapperRef}
                                >
                                    <FontAwesomeIcon icon={faBell} />
                                    <Badge bg="branded">19</Badge>
                                    {isNotification && (
                                        <section className="device-notifications">
                                            <div className="device-notifications-title">
                                                <h3>Notifications</h3>
                                                <div className="notifications-head">
                                                    <FontAwesomeIcon icon={faBell} />
                                                </div>
                                                <hr></hr>
                                            </div>
                                            <div className="notifications-info">
                                                {notifications}
                                            </div>
                                        </section>
                                    )}
                                </div>
                            } */}
                        <button onClick={toggleNav} className="Burger">
                            <img
                                src={require("../assets/images/ios-menu.png")}
                            />
                        </button>
                    </div>
                </div>
            </header>
            <Outlet />
            {dashboardPaths.includes(window.location.pathname) && <AdminNav />}
        </Fragment>
    );
}
